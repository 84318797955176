import React, { CSSProperties, PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import Spinner from './Spinner'

const PrimaryButtonInner = styled.button`
  background-color: ${RADOM_COLORS.BLACK};
  border-radius: 25px;
  padding: 14px 30px;
  outline: 0;
  border: 0;
  color: #FFF;
  cursor: pointer;
  transition: 0.2s ease all;
  font-size: 16px;
  height: fit-content;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transform: scale(1);

  :hover {
    transform: scale(1.005);
    opacity: 0.75;
  }
  
  :active {
    transform: scale(0.995);
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;

    :active {
      color: #FFF;
    }
  }
`

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  style?: CSSProperties
  spinnerColor?: string
}

export const PrimaryButton = ({ disabled, ...props }: PropsWithChildren<ButtonProps>): ReactElement =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  <PrimaryButtonInner style={props.style} disabled={props.isLoading || disabled} {...props}>
    {props.isLoading ? <Spinner style={{ borderColor: props.spinnerColor ?? 'white' }} /> : props.children}
  </PrimaryButtonInner>

export const SecondaryButton = styled.button`
  border: 1px solid;
  border-color: ${RADOM_COLORS.ORANGE};
  border-radius: 5px;
  padding: 10px 30px;
  outline: 0;
  background: none;
  color: ${RADOM_COLORS.ORANGE};
  cursor: pointer;
  transition: 0.2s ease all;
  font-size: 14px;
  height: fit-content;
  user-select: none;
  
  :hover {
    opacity: 0.75;
  }

  :disabled {
    cursor: default;
    border-color: ${RADOM_COLORS.ORANGE_LIGHT};
    color: ${RADOM_COLORS.ORANGE_LIGHT};
    :hover {
      opacity: 1;
    }
  }
  
  :active {
    opacity: 0.5;
  }
`

export const SecondaryLinkButton = styled.a`
  border: 1px solid;
  border-color: ${RADOM_COLORS.ORANGE};
  border-radius: 5px;
  padding: 5px 20px;
  outline: 0;
  background: none;
  color: ${RADOM_COLORS.ORANGE};
  cursor: pointer;
  transition: 0.2s ease all;
  font-size: 12px;
  height: fit-content;
  user-select: none;
  text-decoration: none;
  
  :hover {
    opacity: 0.75;
  }
  
  :active {
    opacity: 0.5;
  }
`

export const PrimaryLinkButton = styled.a`
  background-color: ${RADOM_COLORS.ORANGE};
  border-radius: 5px;
  padding: 10px 30px;
  outline: 0;
  border: 0;
  color: #FFF;
  cursor: pointer;
  transition: 0.2s ease all;
  text-decoration: none;
  font-size: 14px;
  height: fit-content;
  user-select: none;
  
  :hover {
      opacity: 0.75;
  }
  
  :active {
      background-color: #ffd6ce;
      color: ${RADOM_COLORS.ORANGE};
  }
`

export const IconButton = styled.button`
  border: 0;
  border-radius: 5px;
  padding: 5px 20px;
  outline: 0;
  background: none;
  color: ${RADOM_COLORS.ORANGE};
  cursor: pointer;
  transition: 0.2s ease all;
  font-size: 12px;
  height: fit-content;
  user-select: none;
  display: flex;
  align-items: center;
  
  :hover {
    opacity: 0.75;
  }
  
  :active {
    opacity: 0.5;
  }
`
