import React, { CSSProperties, ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'

const LoadingBarAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
`

const LoadingBarInner = styled.div<{ animationSpeed: number }>`
  animation: ${LoadingBarAnimation} ${({ animationSpeed }) => animationSpeed}s alternate infinite linear;
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(-45deg, rgb(155 158 177 / 50%), rgb(155 158 177 / 30%));
  border-radius: 5px;
  width: 50px;
  height: 20px;
`

interface Props {
  animationSpeed?: number
  style?: CSSProperties
}

const LoadingBar = ({ animationSpeed = 0.5, style }: Props): ReactElement => {
  return <LoadingBarInner animationSpeed={animationSpeed} style={style} />
}

export default LoadingBar
