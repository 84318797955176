import React, { ReactElement } from 'react'
import LoadingBar from './LoadingBar'

function LoadingPresale(): ReactElement {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    minHeight: '100%'
  }}>
    <LoadingBar style={{
      width: 450,
      minHeight: 550,
      maxWidth: '100%',
      padding: 30,
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column'
    }} />
  </div>
}

export default LoadingPresale
