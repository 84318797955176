import React, { ChangeEventHandler, ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

interface ITextInputProps {
  label: string
  type?: any
  value: string | number
  pattern?: string
  placeholder?: string
  onChange: ChangeEventHandler<HTMLElement>
  onKeyDown?: React.KeyboardEventHandler
  required?: boolean
}

export const IconButtonInner = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
`

export const BaseInputStyles = `
  border: 1px solid ${RADOM_COLORS.GRAY_DARK};
  border-radius: 5px;
  padding: 8px 6px;
  transition: 0.2s ease border, box-shadow;
  width: 100%;
  outline: none;
  box-shadow: 0 1px 1px rgb(226 228 239 / 50%);
  font-size: 14px;

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARK};
  }

  :focus {
    border-color: ${RADOM_COLORS.GRAY_DARKER};
    box-shadow: 0 0 5px ${RADOM_COLORS.GRAY_DARK};
  }

  ::placeholder {
    color: ${RADOM_COLORS.GRAY_DARKER};
  }
`

export const TextInputWithLabel = (
  { label, type = 'text', value, onChange, placeholder, pattern, onKeyDown, required }: ITextInputProps
): ReactElement => {
  return <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    <InputLabel>{ label }</InputLabel>
    <TextInput
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      pattern={pattern}
      onKeyDown={onKeyDown}
      required={required}
    />
  </div>
}

export const InputLabel = styled.span`
  font-weight: 400;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKER};
`

export const TextInput = styled.input<{ placeholderColor?: string }>`
  ${BaseInputStyles}

  ::placeholder {
    ${props => props.placeholderColor && `color: ${props.placeholderColor} !important;`}
  }
`

export const Cookie = styled.div`
  max-width: min-content;
  background-color: ${RADOM_COLORS.GRAY_MED};
  border-radius: 5px;
  padding: 3px 5px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  font-size: 12px;
  user-select: none;
`
