import React from 'react'

function Copy(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_3_1274)">
      <path d="M16 3H4V16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 7H20V19C20 20.1046 19.1046 21 18 21H10C8.89543 21 8 20.1046 8 19V7Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3_1274">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}

export default Copy
