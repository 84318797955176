import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { ModalFadeIn, ScaleIn } from './Animations'
import { IconButton } from './Button'
import Close from '../icons/Close'

const ModalContainer = styled.div`
  background-color: rgba(7, 7, 40, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${ModalFadeIn} 0.2s;
`

const ModalInner = styled.div`
  background-color: white;
  box-shadow: 0 0 20px rgba(7, 7, 40, 0.5);
  width: 500px;
  max-width: 90%;
  border-radius: 10px;
  animation: ${ScaleIn} 0.1s cubic-bezier(0.18, 0.89, 0.32, 1.15);
  max-height: 90%;
  overflow: scroll;
`

type ModalProps = PropsWithChildren<{
  visible: boolean
  onClose: any
  innerStyles?: React.CSSProperties
  title?: string
}>

export default class Modal extends React.Component<ModalProps> {
  inner: HTMLDivElement | null

  render(): ReactElement {
    if (!this.props.visible) {
      return <></>
    }

    return <ModalContainer
      onClick={(e) => {
        if (!(e.target === this.inner || this.inner?.contains(e.target as any))) {
          this.props.onClose()
        }
      }}>
      <ModalInner
        ref={inner => this.inner = inner}
        style={this.props.innerStyles}>

        {
          this.props.title &&
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 30,
            alignItems: 'center'
          }}>
            <span style={{ fontSize: 16, fontWeight: 500 }}>{this.props.title}</span>
            <IconButton type='button' onClick={() => this.props.onClose()} style={{ padding: 0 }}>
              <Close style={{ width: '14px' }} />
            </IconButton>
          </div>
        }

        {this.props.children}
      </ModalInner>
    </ModalContainer>
  }
}
