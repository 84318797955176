import parse from 'color-parse'
import gradient from 'gradient-parser'

export const shortAddress = (address: string, decimals = 4): string => {
  if (!address) {
    return ''
  }

  return address.substring(0, decimals) + '...' + address.slice(address.length - decimals)
}

export function pickTextColorBasedOnBgColor(bgColor: string, lightColor: string, darkColor: string): string {
  let color = parse(bgColor)

  try {
    const gradientColor = gradient.parse(bgColor)
    color = parse(gradientColor[0].colorStops[0].value)
  } catch {}

  const r = color.values[0]
  const g = color.values[1]
  const b = color.values[2]
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2])
  return (L > 0.179) ? darkColor : lightColor
}

export function formatNumber(val: number, options?: Intl.NumberFormatOptions): string {
  return Intl.NumberFormat('en-US', options).format(val)
}

export function simpleEmailValidation(address: string): boolean {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(address)
}
