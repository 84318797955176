import axios from 'axios'
import { CheckoutCustomizations } from '../components/Checkout'
import { API_BASE_URL } from '../util/Constants'

export interface IProduct {
  id: string
  name: string
  description: string
  chargingIntervalSeconds: number
  currency: string
  price: number
  createdAt: Date
  imageUrl: string
  updatedAt: Date
  sellerAddress: string
  productType?: {
    Presale?: {
      Token?: {
        decimals?: any
        ticker?: any
      }
    }
  }
  nextBillingDateAt?: string
}

export interface AcceptedToken {
  tokenAddress: string
  chainId: number
}

export enum InputFieldDataType {
  String = 'String',
  Number = 'Number',
  Email = 'Email',
  Address = 'Address'
}

export interface InputField {
  dataType: InputFieldDataType
  inputLabel: string
  isRequired: boolean
}

export type InputFieldWithValue = InputField & { value: string | number | boolean }

export interface IManagedPaymentMethod {
  network: string
  token?: string
  discountPercentOff?: number
}

export interface IManagedGateway {
  methods: IManagedPaymentMethod[]
}

export interface ISelfCustodialGateway {
  tokens: AcceptedToken[]
}

export interface IPaymentLink {
  id: string
  url: string
  products: IProduct[]
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  customizations: CheckoutCustomizations
  sellerAddress: string
  sellerLogoUrl: string
  sellerName: string
  createdAt: Date
  updatedAt: Date
  successUrl?: string
  errorUrl?: string
  inputFields: InputField[]
  chargeCustomerNetworkFee: boolean
  presaleData?: any
}

export interface IDiscountCode {
  id: string
  code: string
  model: {
    modelType: 'percentage' | 'fixed'
    amountOff: number
  }
  products: string[]
  createdAt: string
  updatedAt: string
}

export interface PriceQuote {
  from: string
  to: string
  value: number
  expiryTimestamp: string
}

export interface Profile {
  id: string
  email: string
  name: string
  billingAddress?: string
  phone?: string
  telegram?: string
  discord?: string
  logoUrl?: string
}

export type SellerProfile = Profile & { sellerAddress: string }

export interface ProductWithQuantity {
  product: IProduct
  quantity: number
}

export interface LineItem {
  invoiceId: string
  currency?: string
  name: string
  quantity: number
  price: number
}

export interface IInvoice {
  id: string
  sellerAddress: string
  seller: Profile
  gateway: {
    managed?: IManagedGateway
    selfCustodial?: ISelfCustodialGateway
  }
  customer: Profile
  tokens: AcceptedToken[]
  products: ProductWithQuantity[]
  lineItems: LineItem[]
  issuedAt: Date
  paidAt?: Date
  voidedAt?: Date
  overdueAt?: Date
  inputData: Array<{ key: string, value: string }>
  status: 'paid' | 'pending' | 'voided' | 'overdue'
  memo?: string
}

export type ICreateInvoice = Omit<IInvoice, 'id' | 'customer' | 'issuedAt' | 'paidAt' | 'voidedAt' | 'status'> & { 'customerIds': string[] }

export interface KeyValuePair {
  key: string
  value: string
}

export interface CheckoutItemData {
  name: string
  description?: string
  quantity: number
  chargingIntervalSeconds: number
  imageUrl?: string
  currency?: string
  price: number
  nextBillingDateAt?: string
}

export interface CheckoutSession {
  id: string
  organizationId: string
  sessionStatus: string
  sellerAddress: string
  products: IProduct[]
  items: CheckoutItemData[]
  total?: number
  currency?: string
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  successUrl: string
  cancelUrl?: string
  metadata?: KeyValuePair[]
  expiresAt?: Date
  createdAt?: Date
  updatedAt?: Date
  customizations?: any
  chargeCustomerNetworkFee?: boolean
}

export interface PaymentMethod {
  selfCustodial?: {
    buyerAddress?: string
    tokenAddress?: string
    chainId: number
  }
  managed?: {
    method: IManagedPaymentMethod
  }
  managedTokenDelegate?: {
    buyerAddress?: string
    token?: string
    network: string
  }
}

export interface NewPendingPaymentLinkOrder {
  paymentLinkId: string
  paymentMethod: PaymentMethod
  orderData: KeyValuePair[]
  orderProductData?: any[]
  discountCode?: string
  emailAddress?: string
}

export interface NewPendingDonationLinkOrder {
  donationLinkId: string
  paymentMethod: IManagedPaymentMethod
  orderData: KeyValuePair[]
  amount: number
  currency: string
}

export interface NewPendingCheckoutSessionOrder {
  checkoutSessionId: string
  paymentMethod: PaymentMethod
  orderData: KeyValuePair[]
  orderProductData: any[]
  emailAddress?: string
  discountCode?: string
}

export interface ICheckoutSessionOrder {
  id: string
  checkoutSessionId: string
  paymentMethodDetails: PaymentMethodDetails
  createdAt: Date
}

export type INetwork = 'Bitcoin' | 'Ethereum' | 'Solana' | 'SepoliaTestnet' | 'Polygon' | 'PolygonTestnet' | 'BNB' | 'BNBTestnet' | 'Zcash' | 'ZcashTestnet' | 'Base' | 'Arbitrum' | 'Avalanche' | 'SolanaDevnet' | 'Polkadot' | 'PolkadotTestnet'

export interface IManagedPaymentMethodDetails {
  paymentId: string
  paymentAddress: string
  network: INetwork
  token?: string
  amount: string
  expiresAt: string
}

export interface IManagedTokenDelegatePaymentMethodDetails {
  paymentId: string
  messageToSign: string
  network: INetwork
  token: string
  amount: string
}

export interface IPaymentLinkOrder {
  id: string
  paymentLinkId: string
  paymentMethodDetails: PaymentMethodDetails
  orderTotal: number
  createdAt: string
}

export interface IDonationLinkOrder {
  id: string
  donationLinkId: string
  paymentMethodDetails: PaymentMethodDetails
  orderTotal: number
  createdAt: string
}

export interface SubscriptionPaymentRes {
  id: string
  paymentMethodDetails: PaymentMethodDetails
  createdAt: string
}

export interface PaymentMethodDetails {
  managed?: IManagedPaymentMethodDetails
  managedTokenDelegate?: IManagedTokenDelegatePaymentMethodDetails
  selfCustodial?: {
    sellerAddress: string
    buyerAddress: string
    tokenAddress: string
    chainId: number
    orderHash: string
    orderInput: string
  }
}

export interface NewPendingInvoicePayment {
  invoiceId: string
  paymentMethod: {
    selfCustodial?: {
      buyerAddress?: string
      tokenAddress?: string
      chainId: number
    }
    managed?: {
      method: IManagedPaymentMethod
    }
  }
}

export interface IInvoicePayment {
  id: string
  invoiceId: string
  paymentMethodDetails: PaymentMethodDetails
  createdAt: string
}

export interface PendingInvoicePaymentResponse {
  id: string
  invoiceId: string
  paymentMethodDetails: {
    managed?: IManagedPaymentMethodDetails
    selfCustodial?: {
      sellerAddress: string
      buyerAddress: string
      tokenAddress: string
      chainId: number
      orderHash: string
      orderInput: string
    }
  }
  createdAt: Date
  orderTotal: number
}

export interface PaymentTransaction {
  amount: string
  transactionHash: string
  network: INetwork
  token?: string
}

export interface IManagedPaymentStatus {
  status: 'pending' | 'paymentDetected' | 'paymentConfirmed' | 'expired'
  amountRemaining: string
  transactions: PaymentTransaction[]
  pendingTransactions: PaymentTransaction[]
  expiresAt?: string
}

export type ManagedSubscriptionPeriod = 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'annually' | 'custom'

export interface SubscriptionPayment {
  date: string
  network: INetwork
  token?: string
  amount: string
  transactionHashes: string[]
}

export interface Subscription {
  id: string
  organizationId: string
  associatedEntityType: 'paymentLinkOrder' | 'checkoutSession' | 'invoice' | 'paymentSession'
  associatedEntityId: string
  subscriptionType: {
    automatedEVMSubscription?: {
      buyerAddress: string
      subscriptionContractAddress: string
      recommendedAllowanceDuration?: number
    }
    emailInvoiceSubscription?: {
      emailAddress: string
    }
  }
  status: 'active' | 'cancelled' | 'expired'
  network: string
  token?: string
  amount: string
  currency: string
  period: ManagedSubscriptionPeriod
  periodCustomDuration?: number
  tags?: { [key: string]: string }
  nextBillingDateAt: string
  createdAt: string
  product?: IProduct
  customizations?: CheckoutCustomizations
  payments: SubscriptionPayment[]
}

export interface RedeemDiscount {
  paymentLinkId?: string
  checkoutSessionId?: string
  code: string
}
export interface IDonationLink {
  id: string
  url: string
  name: string
  currency: string
  createdAt: Date
  updatedAt: Date
  primaryButtonColor: string
  inputFields: InputField[]
  methods: IManagedPaymentMethod[]
  imageUrl?: string
  bannerImageUrl?: string
  tosUrl?: string
}

class Radom {
  getPaymentLink = async (id: string): Promise<IPaymentLink> => {
    return await axios.get(`${API_BASE_URL}/payment_link/${id}`).then(res => res.data)
  }

  getDonationLink = async (id: string): Promise<IDonationLink> => {
    return await axios.get(`${API_BASE_URL}/donation_link/${id}`).then(res => res.data)
  }

  getInvoice = async (id: string): Promise<IInvoice> => {
    return await axios.get(`${API_BASE_URL}/invoice/${id}`).then(res => res.data)
  }

  redeemDiscountCode = async(req: RedeemDiscount): Promise<IDiscountCode> => {
    return await axios.post(`${API_BASE_URL}/discount_code/verify`, req).then(res => res.data)
  }

  getPriceQuotes = async(from: string[], to: string[]): Promise<PriceQuote[]> => {
    const fromCurrencies = from.map((f, i) => `from[${i}]=${f}`).join('&')
    const toCurrencies = to.map((f, i) => `to[${i}]=${f}`).join('&')
    return await axios.get(`${API_BASE_URL}/price_quotes?${fromCurrencies}&${toCurrencies}`).then(res => res.data)
  }

  getCheckoutSession = async (id: string): Promise<CheckoutSession> => {
    return await axios.get(`${API_BASE_URL}/checkout_session/${id}`).then(res => res.data)
  }

  getSellerProfile = async (address: string): Promise<SellerProfile> => {
    return await axios.get(`${API_BASE_URL}/sellers/${address}`).then(res => res.data)
  }

  createPaymentLinkOrder = async (data: NewPendingPaymentLinkOrder): Promise<IPaymentLinkOrder> => {
    return await axios.post(`${API_BASE_URL}/payment_link_order/pending`, data)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  createDonationLinkOrder = async (data: NewPendingDonationLinkOrder): Promise<IDonationLinkOrder> => {
    return await axios.post(`${API_BASE_URL}/donation_link_order/pending`, data)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  createCheckoutSessionOrder = async (data: NewPendingCheckoutSessionOrder): Promise<ICheckoutSessionOrder> => {
    return await axios.post(`${API_BASE_URL}/checkout/pending`, data)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  createInvoicePayment = async (data: NewPendingInvoicePayment): Promise<PendingInvoicePaymentResponse> => {
    return await axios.post(`${API_BASE_URL}/invoices/pending`, data).then(res => res.data)
  }

  getManagedPaymentStatus = async(paymentId: string): Promise<IManagedPaymentStatus> => {
    return await axios.get(`${API_BASE_URL}/managed_payment_status/${paymentId}`).then(res => res.data)
  }

  completeTokenDelegatePayment = async(paymentId: string, signature: string): Promise<void> => {
    return await axios.post(`${API_BASE_URL}/token_delegate_payment`, { paymentId, signature })
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  getSubscription = async (id: string): Promise<Subscription> => {
    return await axios.get(`${API_BASE_URL}/subscription/${id}`)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  estimateTokenTranferGas = async (network: string, token: string): Promise<{ gasCost: string }> => {
    return await axios.get(`${API_BASE_URL}/estimate_token_transfer_gas?network=${network}&token=${token}`)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  createSubscriptionPayment = async(subscriptionId: string): Promise<SubscriptionPaymentRes> => {
    return await axios.post(`${API_BASE_URL}/subscription/${subscriptionId}/pay`)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }

  cancelSubscription = async (id: string): Promise<void> => {
    return await axios.post(`${API_BASE_URL}/subscription/${id}/cancel`)
      .then(res => res.data)
      .catch(err => { throw new Error(err.response.data.error) })
  }
}

export default new Radom()
