import { keyframes } from 'styled-components'

export const ModalFadeIn = keyframes`
  from {
    background-color: rgba(7, 7, 40, 0);
  }
  to {
    background-color: rgba(7, 7, 40, 0.75);
  }
`

export const ScaleIn = keyframes`    
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const ExpandOut = keyframes`
  from {
    /* transform: scaleY(0); */
    height: 0;
  }
  to {
    /* transform: scaleY(1) */
    height: fit-content;
  }
`

export const CollapseIn = keyframes`
  from {
    /* transform: scaleY(1) */
  }
  to {
    /* transform: scaleY(0) */
  }
`

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
 100% {
    opacity: 0;
  }
`
