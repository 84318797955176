import styled, { keyframes } from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { ScaleIn } from './Animations'
import { Token } from '../util/Tokens'

const ReceiptIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const SuccessWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  animation: ${ReceiptIn} 0.5s ease;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
  padding: 40px;
`

export const CheckmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: ${RADOM_COLORS.NICE_GREEN};
  animation: ${ScaleIn} 0.5s ease;
`

export interface IReceipt {
  chainId: number
  buyerAddress: string
  sellerAddress: string
  token: Token
  total: number
}
